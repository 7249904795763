import { FILTER_QUERY_ACTIONS_TYPES } from "../consts";
import { GenericActions, GenericFilters } from "../types";

export function persistReducerFunction(
  state: GenericFilters,
  action: GenericActions,
) {
  if (action.type === FILTER_QUERY_ACTIONS_TYPES.CLEAR) {
    return { tab: state.tab };
  }
  if (action.type === FILTER_QUERY_ACTIONS_TYPES.SET_STATE) {
    return action.value;
  }
  if (
    action.type === FILTER_QUERY_ACTIONS_TYPES.RESET ||
    action.type === FILTER_QUERY_ACTIONS_TYPES.RESET_TO_INITIAL
  ) {
    return state;
  }
  return { ...state, [action.type]: action?.value };
}
