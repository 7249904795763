import { useCallback, useState } from "react";

const generateUID = () => new Date().getUTCMilliseconds();

export default function useRemountComponent(): [number, () => void] {
  const [key, setKey] = useState(() => generateUID());

  const remount = useCallback(() => {
    setKey(() => generateUID());
  }, []);

  return [key, remount];
}
