import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export type QueryData = {
  [index: string]: any;
};

function getJSONString(uriDecoded: string | null | undefined) {
  if (!uriDecoded) return uriDecoded;
  let parsedJSON;
  try {
    parsedJSON = JSON.parse(uriDecoded);
  } catch {
    parsedJSON = uriDecoded;
  }
  return parsedJSON;
}

function decodeURLData<ParamData extends QueryData>({
  defaultValues,
  search,
}: {
  defaultValues: ParamData;
  search: URLSearchParams;
}): ParamData {
  const params = {} as ParamData;
  Object.entries(defaultValues).forEach(([paramName, defaultValue]) => {
    let value = defaultValue;
    const paramValue = search.get(paramName);
    if (paramValue) {
      value = decodeURIComponent(paramValue);
    }

    const parsedJSON = getJSONString(value);

    params[paramName as keyof ParamData] = parsedJSON;
  });
  return params;
}

export function useQueryParam<ParamData extends QueryData>(
  defaultValues: ParamData,
): [ParamData, React.Dispatch<React.SetStateAction<ParamData>>] {
  const [search, setSearch] = useSearchParams();
  const params = decodeURLData({ defaultValues, search });
  const [dataFromQueryParam, setDataForQueryParam] =
    useState<ParamData>(params);

  useEffect(() => {
    if (!dataFromQueryParam) return;
    Object.entries(dataFromQueryParam).forEach(([paramName, paramValue]) => {
      const parsedJSON =
        typeof paramValue === "object"
          ? JSON.stringify(paramValue)
          : paramValue;

      const URIencoded = encodeURIComponent(parsedJSON);

      search.set(paramName, URIencoded);
    });
    setSearch(search);
  }, [dataFromQueryParam]);

  return [dataFromQueryParam, setDataForQueryParam];
}

export default useQueryParam;
