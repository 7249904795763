import { List } from "immutable";

/**
 * File Feature v1
 */

export type FileMimeType = (typeof FileMimeTypes)[keyof typeof FileMimeTypes];
export const FileMimeTypes = {
  PDF: "application/pdf",
  DOC: "application/msword",
  DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  JPG_JPEG: "image/jpeg",
  PNG: "image/png",
  TIFF: "image/tiff",
  XLS: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XL: "application/excel",
  XL2: "application/x-excel",
  XL3: "application/x-msexcel",
  XL4: "application/vnd.ms-excel",
} as const;

export const mimeTypeToExtensionMap: { [key in FileMimeType]: string } = {
  [FileMimeTypes.PDF]: ".pdf",
  [FileMimeTypes.DOC]: ".doc",
  [FileMimeTypes.DOCX]: ".docx",
  [FileMimeTypes.JPG_JPEG]: ".jpg",
  [FileMimeTypes.PNG]: ".png",
  [FileMimeTypes.TIFF]: ".tiff",
  [FileMimeTypes.XLS]: ".xlsx",
  [FileMimeTypes.XL]: ".xls",
  [FileMimeTypes.XL2]: ".xls",
  [FileMimeTypes.XL3]: ".xls",
  [FileMimeTypes.XL4]: ".xls",
};

export const ALLOWED_FILE_MIME_TYPES = List(Object.values(FileMimeTypes));

/**
 * File Feature v2
 */

export const ALLOWED_EXTENSIONS_HIS = ["pdf", "doc", "docx"] as const;
export const MAX_FILE_SIZE = 21e6; // 21MB
