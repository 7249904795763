import {
  activateVersionRefreshBanner,
  isProd,
} from "core/model/utils/featureFlags";
import { Banner } from "ds/ui";
import { Z_INDEX_MAX, dp, sizing } from "ds_legacy/materials/metrics";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useTranslations } from "translations";
import { usePrint } from "../Contexts";

export function RefreshBanner({ onClick }: { onClick?: AnyFunction }) {
  const translations = useTranslations();
  const { isMobile } = useMedia();
  const print = usePrint();

  if (print) return null;

  return (
    <div
      data-testid="refreshBanner"
      style={{
        position: "fixed",
        left: "50%",
        top: sizing(6.5),
        width: sizing(isMobile ? 50 : 64),
        transform: "translateX(-50%)",
        zIndex: Z_INDEX_MAX,
      }}
    >
      <div className="mt-2 w-full">
        <Banner
          message={translations.general.refreshToSeeChanges}
          color="warning"
          actions={[
            {
              id: "refresh",
              label: translations.actions.refresh,
              onClick: onClick ? onClick : () => window.location.reload(),
            },
          ]}
        />
      </div>
    </div>
  );
}

export function RefreshBannerVersionChecker({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  const translations = useTranslations();
  const print = usePrint();

  if (print) return null;

  if (!activateVersionRefreshBanner) {
    return null;
  }

  return (
    <div
      style={{
        bottom: dp(20),
        left: "50%",
        position: "fixed",
        transform: "translateX(-50%)",
        zIndex: Z_INDEX_MAX,
      }}
    >
      <div className="mt-2 w-full">
        <Banner
          message={translations.todo.refreshApp}
          color="primary"
          actions={[
            {
              id: "refresh",
              label: translations.actions.refresh,
              onClick: () => window.location.reload(),
            },
            ...(!isProd
              ? [
                  {
                    id: "dismiss",
                    label: translations.actions.dismiss,
                    onClick: onDismiss,
                  },
                ]
              : []),
          ]}
        />
      </div>
    </div>
  );
}
