import {
  APP_CAREPROVIDER,
  APP_CLINIC,
  CAREPROVIDER_TAB_TYPE_NEW,
  REDIRECT_PARAM,
} from "core/consts";
import { ENV_PRODUCTION, isFrance } from "core/model/config";
import { isDev } from "core/model/utils/featureFlags";
import { getBaseUrl, getQueryVariable, pathToUrl } from "core/model/utils/urls";
import { Env, MessengerTabKinds } from "core/types";
import { setUpdatedRequest } from "dsl/ecosystems/AuctionRequest/updatedRequest";
import { Location, NavigateFunction } from "react-router-dom";
import { generateParams } from "./helpers";

export const getCareproviderHandlerUrls = ({
  location,
  loggedCareproviderId,
}: {
  location: Location;
  loggedCareproviderId?: number;
}) => {
  const { search } = location;

  return {
    goToProductionEnv() {
      return getBaseUrl({ env: ENV_PRODUCTION, isDev, app: APP_CAREPROVIDER });
    },
    goToContactUs() {
      return isFrance
        ? "https://recaresolutions.fr/nous-contacter/"
        : "https://recaresolutions.com/kontakt/";
    },
    goToSenderApp() {
      const env = getQueryVariable(search, "env") as Env;
      return getBaseUrl({ env, isDev, app: APP_CLINIC });
    },
    goToLogin() {
      return pathToUrl({ pathname: "/careprovider/auth", search });
    },
    goToForgotPassword() {
      return pathToUrl({ pathname: "/careprovider/forgot-password", search });
    },
    // Authentication
    replaceByAuth() {
      return pathToUrl({ pathname: "/careprovider/auth", search: undefined });
    },
    changePassword(id?: number) {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${
          id ?? loggedCareproviderId
        }/my_password_change`,
        search: `${REDIRECT_PARAM}=${encodeURIComponent(
          `${location.pathname}${location.search}`,
        )}`,
      });
    },
    goToChangePassword() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/my_password_change`,
        search: `${REDIRECT_PARAM}=${encodeURIComponent(
          `${location.pathname}${location.search}`,
        )}`,
      });
    },

    // User account
    goToProfile(id: number) {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${id}/my_account`,
        search: location.search,
      });
    },

    // Onboarding
    goToPersonalOnboarding({
      careproviderId,
      step = "account",
    }: {
      careproviderId: number;
      step?: string;
    }) {
      return pathToUrl({
        pathname: `/careprovider/newonboarding/personal/${careproviderId}/${step}`,
        search: location.search,
      });
    },

    // Dashboard
    goToRequestDashboard(
      parameters: {
        pgs?: string;
        routeTab?: string;
        updatedRequestId?: number;
      } = {},
    ) {
      const { pgs, routeTab, updatedRequestId } = {
        routeTab: CAREPROVIDER_TAB_TYPE_NEW,
        ...parameters,
      };

      if (updatedRequestId) setUpdatedRequest(updatedRequestId);
      const search = generateParams([["pgs", pgs]]) || "";

      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/requests/${routeTab}`,
        search,
      });
    },

    goToFilteredDashboard(filterId?: number) {
      const search = generateParams([["filterid", filterId]]);

      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/requests/new`,
        search,
      });
    },

    // Requests
    goToRequest(requestId: number, careproviderId: number) {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${careproviderId}/requests/${requestId}`,
        search: undefined,
      });
    },
    goToAuctionRequest({
      params,
      requestId,
    }: {
      params?: {
        pgs?: string;
        position?: number | null;
        recommendation?: boolean;
      };
      requestId: number;
    }) {
      const search = generateParams([
        ["pgs", params?.pgs],
        ["position", params?.position],
        ["recommendation", params?.recommendation ? "true" : undefined],
      ]);
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/requests/${requestId}`,
        search,
      });
    },
    goToAuctionRequestTab({
      requestId,
      tab,
    }: {
      requestId: number;
      tab: MessengerTabKinds;
    }) {
      const search = new URLSearchParams(location.search);
      search.set("activeTab", tab);
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/requests/${requestId}`,
        search: search.toString(),
      });
    },
    goToRequestsLogged(careproviderId: number, updatedRequest?: number) {
      const search = updatedRequest ? `?updatedRequest=${updatedRequest}` : "";
      return pathToUrl({
        pathname: `/careprovider/dashboard/${careproviderId}/requests`,
        search,
      });
    },
    goToBarthel({
      auctionRequestId,
      careproviderId,
    }: {
      auctionRequestId: number;
      careproviderId: number;
    }) {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${careproviderId}/requests/${auctionRequestId}/barthel-index`,
        search: undefined,
      });
    },
    goToEarlyRehab({
      auctionRequestId,
      careproviderId,
    }: {
      auctionRequestId: number;
      careproviderId: number;
    }) {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${careproviderId}/requests/${auctionRequestId}/early-rehab-index`,
        search: undefined,
      });
    },
    goToSinger({
      auctionRequestId,
      careproviderId,
    }: {
      auctionRequestId: number;
      careproviderId: number;
    }) {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${careproviderId}/requests/${auctionRequestId}/singer-index`,
        search: undefined,
      });
    },

    // Notifications
    goToCareproviderNotificationPanel() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/notification_panel`,
        search: undefined,
      });
    },

    // Settings
    goToSettingsAccount(careproviderId: number) {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${careproviderId}/settings/accounts`,
        search: undefined,
      });
    },
    goToCareproviderSettings() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/settings`,
        search: undefined,
      });
    },
    goToCareproviderSettingsAccount() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/settings/account`,
        search: undefined,
      });
    },
    goToCareproviderSettingsCompanyProfile() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/settings/profile`,
        search: undefined,
      });
    },
    goToCareproviderSettingsServices() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/settings/services`,
        search: undefined,
      });
    },
    goToCareproviderSettingsSearch() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/settings/search`,
        search: undefined,
      });
    },
    goToCareproviderSettingsTeam() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/settings/team`,
        search: undefined,
      });
    },
    goToCareproviderSettingsNotifications() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/settings/notifications`,
        search: undefined,
      });
    },
    goToCareproviderSettingsPWA() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/settings/pwa`,
        search: undefined,
      });
    },
    goToCareproviderSettingsPartnerClinics() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/settings/partner_clinics`,
        search: undefined,
      });
    },
    goToCareproviderSettingsLegalDocuments() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/settings/legal_documents`,
        search: undefined,
      });
    },
    goToCareproviderSettingsCapacity() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/settings/capacity`,
        search: undefined,
      });
    },
    goToCareproviderSettingsExtensions() {
      return pathToUrl({
        pathname: `/careprovider/dashboard/${loggedCareproviderId}/settings/extensions`,
        search: undefined,
      });
    },
  } as const;
};

export const getCareproviderNavigationHandlers = ({
  careproviderId,
  location,
  navigate,
}: {
  careproviderId?: number;
  location: Location;
  navigate: NavigateFunction;
}) => {
  const urls = getCareproviderHandlerUrls({
    loggedCareproviderId: careproviderId,
    location,
  });
  return {
    // Authentication
    goToLogin() {
      navigate(urls.goToLogin());
    },
    goToForgotPassword() {
      navigate(urls.goToForgotPassword());
    },
    replaceByAuth() {
      navigate(urls.replaceByAuth(), { replace: true });
    },
    changePassword(id: number) {
      navigate(urls.changePassword(id));
    },
    goToChangePassword() {
      navigate(urls.goToChangePassword());
    },

    // User account
    goToProfile(id: number) {
      navigate(urls.goToProfile(id));
    },

    // Onboarding
    goToPersonalOnboarding({
      careproviderId,
      step = "account",
    }: {
      careproviderId: number;
      step?: string;
    }) {
      navigate(urls.goToPersonalOnboarding({ careproviderId, step }));
    },

    // Dashboard
    goToRequestDashboard(
      parameters: {
        pgs?: string;
        routeTab?: string;
        updatedRequestId?: number;
      } = {},
    ) {
      const { pgs, routeTab, updatedRequestId } = {
        routeTab: CAREPROVIDER_TAB_TYPE_NEW,
        ...parameters,
      };

      if (updatedRequestId) setUpdatedRequest(updatedRequestId);

      navigate(urls.goToRequestDashboard({ pgs, routeTab, updatedRequestId }));
    },

    goToFilteredDashboard(filterId?: number) {
      navigate(urls.goToFilteredDashboard(filterId));
    },

    // Requests
    goToRequest(requestId: number, careproviderId: number) {
      navigate(urls.goToRequest(requestId, careproviderId));
    },
    goToAuctionRequest({
      params,
      requestId,
    }: {
      params?: {
        pgs?: string;
        position?: number | null;
        recommendation?: boolean;
      };
      requestId: number;
    }) {
      navigate(urls.goToAuctionRequest({ params, requestId }));
    },
    goToAuctionRequestTab({
      requestId,
      tab,
    }: {
      requestId: number;
      tab: MessengerTabKinds;
    }) {
      navigate(urls.goToAuctionRequestTab({ tab, requestId }));
    },
    goToRequestsLogged(careproviderId: number, updatedRequest?: number) {
      navigate(urls.goToRequestsLogged(careproviderId, updatedRequest));
    },
    goToBarthel({
      auctionRequestId,
      careproviderId,
    }: {
      auctionRequestId: number;
      careproviderId: number;
    }) {
      navigate(urls.goToBarthel({ auctionRequestId, careproviderId }));
    },
    goToEarlyRehab({
      auctionRequestId,
      careproviderId,
    }: {
      auctionRequestId: number;
      careproviderId: number;
    }) {
      navigate(urls.goToEarlyRehab({ auctionRequestId, careproviderId }));
    },
    goToSinger({
      auctionRequestId,
      careproviderId,
    }: {
      auctionRequestId: number;
      careproviderId: number;
    }) {
      navigate(urls.goToSinger({ auctionRequestId, careproviderId }));
    },

    // Notifications
    goToCareproviderNotificationPanel() {
      navigate(urls.goToCareproviderNotificationPanel());
    },

    // Settings
    goToSettingsAccount(careproviderId: number) {
      navigate(urls.goToSettingsAccount(careproviderId));
    },
    goToCareproviderSettings() {
      navigate(urls.goToCareproviderSettings());
    },
    goToCareproviderSettingsAccount() {
      navigate(urls.goToCareproviderSettingsAccount());
    },
    goToCareproviderSettingsCompanyProfile() {
      navigate(urls.goToCareproviderSettingsCompanyProfile());
    },
    goToCareproviderSettingsServices() {
      navigate(urls.goToCareproviderSettingsServices());
    },
    goToCareproviderSettingsSearch() {
      navigate(urls.goToCareproviderSettingsSearch());
    },
    goToCareproviderSettingsTeam() {
      navigate(urls.goToCareproviderSettingsTeam());
    },
    goToCareproviderSettingsNotifications() {
      navigate(urls.goToCareproviderSettingsNotifications());
    },
    goToCareproviderSettingsPWA() {
      navigate(urls.goToCareproviderSettingsPWA());
    },
    goToCareproviderSettingsPartnerClinics() {
      navigate(urls.goToCareproviderSettingsPartnerClinics());
    },
    goToCareproviderSettingsLegalDocuments() {
      navigate(urls.goToCareproviderSettingsLegalDocuments());
    },
    goToCareproviderSettingsCapacity() {
      navigate(urls.goToCareproviderSettingsCapacity());
    },
    goToCareproviderSettingsExtensions() {
      navigate(urls.goToCareproviderSettingsExtensions());
    },
  };
};
