import { ReactNode, createContext, useContext } from "react";
import {
  InstallPromptContext,
  defaultInstallPromptContext,
  useAddToHomescreenPrompt,
} from "./InstallPermission";
import {
  NotificationPermissionContextType,
  defaultNotificationPermission,
  useAskNotificationPermission,
} from "./NotificationPermission";
import { useForceUpdateServiceWorker } from "./ForceUpdateServiceWorker";

type BrowserPermissionContext = {
  install: InstallPromptContext;
  notifications: NotificationPermissionContextType;
};

export const BrowserPermissionContext = createContext<BrowserPermissionContext>(
  {
    notifications: {
      resubscribeUser: async () => {},
      askPermission: async () => {},
      notificationPermissionState: defaultNotificationPermission,
      pushSubscription: null,
    },
    install: defaultInstallPromptContext,
  },
);

export const useBrowserPermissionContext = () =>
  useContext(BrowserPermissionContext);

export const BrowserPermissionContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const notificationContext = useAskNotificationPermission();
  const installContext = useAddToHomescreenPrompt();

  useForceUpdateServiceWorker();

  return (
    <BrowserPermissionContext.Provider
      value={{
        install: installContext,
        notifications: notificationContext,
      }}
    >
      {children}
    </BrowserPermissionContext.Provider>
  );
};
