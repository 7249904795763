import { Dialog, DialogContent, IconButton } from "@mui/material";
import { usePreventScroll } from "@react-aria/overlays";
import LinkV2, {
  ExternalLinkType,
  useExternalLinkLabel,
} from "ds_legacy/components/LinkV2";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, padding, sizing } from "ds_legacy/materials/metrics";
import { BCP_BASE_DARK } from "ds_legacy/materials/palettes/bcp";
import {
  Body,
  Caption,
  FONT_SIZE_12,
  FONT_SIZE_14,
  FONT_WEIGHT_SEMI_BOLD,
} from "ds_legacy/materials/typography";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useFocusElement } from "dsl/hooks/useFocusElement";
import { XIcon } from "lucide-react";
import { CSSProperties, ReactNode } from "react";
import { emailDef } from "react-forms-state";
import { useTranslations } from "translations";
import { TranslationComposition } from "translations/helpers";

export const MODAL_MIN_WIDTH = dp(530);
const MODAL_PADDING_CLOSE_BUTTON = padding(2, 3, 1);
const MODAL_PADDING_CONTENT = padding(0, 3, 2);

export const PROVIDER_SEARCH_LOGIN_MODAL = 1;
export const PROVIDER_SEARCH_LOGIN_MODAL_SUCCESS = 2;
export const PROVIDER_SEARCH_SIGNUP_MODAL = 3;
export const PROVIDER_SEARCH_SIGNUP_MODAL_SUCCESS = 4;

export type ProviderSearchLoginModalType =
  | typeof PROVIDER_SEARCH_LOGIN_MODAL
  | typeof PROVIDER_SEARCH_LOGIN_MODAL_SUCCESS
  | typeof PROVIDER_SEARCH_SIGNUP_MODAL
  | typeof PROVIDER_SEARCH_SIGNUP_MODAL_SUCCESS
  | null;

export const CloseIconButton = ({
  disabled,
  onClose,
}: {
  disabled?: boolean;
  onClose: () => void;
}) => {
  const translations = useTranslations();

  return (
    <HorizontalLayout
      overflow="visible"
      padding={MODAL_PADDING_CLOSE_BUTTON}
      justify="flex-end"
      margin={margin(0, 0, -1, 0)}
    >
      <IconButton
        data-testid="close-modal"
        disabled={disabled}
        size="medium"
        aria-label={translations.actions.close}
        onClick={onClose}
        sx={{
          boxSizing: "border-box",
          color: BCP_BASE_DARK,
        }}
      >
        <XIcon size={16} />
      </IconButton>
    </HorizontalLayout>
  );
};

export const CaptionWithLink = ({
  href,
  marginOverride,
  message,
  textAlign,
  type = "website",
}: {
  href: string;
  marginOverride?: string;
  message: string;
  textAlign?: CSSProperties["textAlign"];
  type?: ExternalLinkType;
}) => {
  const externalLinkLabel = useExternalLinkLabel();

  return (
    <Caption
      margin={marginOverride ?? margin(0)}
      fontSize={FONT_SIZE_12}
      whiteSpace="normal"
      textAlign={textAlign}
    >
      <TranslationComposition translations={message} withOptions>
        {([before, link, after]) => (
          <>
            {before}
            <LinkV2
              aria-label={externalLinkLabel(link, type)}
              href={href}
              target="_blank"
              data-testid="caption-link"
            >
              {link}
            </LinkV2>
            {after}
          </>
        )}
      </TranslationComposition>
    </Caption>
  );
};

export function ProviderSearchDialog({
  onClose,
  children,
  fullScreenOverride,
  paperStyle = {},
  ariaLive,
  style,
  testId,
  focusDelay,
}: {
  ariaLive?: "polite" | undefined;
  children: ReactNode;
  focusDelay?: number;
  fullScreenOverride?: boolean;
  onClose?: () => void;
  paperStyle?: CSSProperties;
  style?: CSSProperties;
  testId?: string;
}) {
  usePreventScroll();
  const focusElement = useFocusElement<HTMLDivElement>({
    fallbackRestoreRef: document.querySelector("h1")!,
    focusFirstInnerElement: true,
    restoreFocus: true,
    setTabIndexFallbackRestoreRef: true,
    delay: focusDelay,
  });
  const { isMobile } = useMedia();
  const fullScreen =
    fullScreenOverride !== undefined ? fullScreenOverride : isMobile;

  function handleClose() {
    onClose?.();
  }

  return (
    <Dialog
      aria-live={ariaLive}
      data-testid={testId}
      fullScreen={fullScreen}
      onClose={handleClose}
      disableRestoreFocus
      disableAutoFocus
      open
      PaperProps={{
        sx: {
          maxWidth: "100%",
          boxSizing: "border-box",
          ...paperStyle,
        },
        "aria-modal": "true",
      }}
      sx={{ ...style }}
      transitionDuration={0}
      disableEscapeKeyDown // this is necessary because of the usage of the ComboBox component inside the Dialogs. When pressing enter, the ComboBox's value container should close, not the dialog
      ref={focusElement}
    >
      <CloseIconButton onClose={handleClose} />
      {children}
    </Dialog>
  );
}

export const DialogContentWrapper = ({
  children,
  sx,
  testId,
}: {
  children: ReactNode;
  sx?: CSSProperties;
  testId: string;
}) => {
  return (
    <DialogContent
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        padding: MODAL_PADDING_CONTENT,
        overflowX: "hidden",
        ...sx,
      }}
      data-testid={testId}
    >
      {children}
    </DialogContent>
  );
};

export const providerSearchEmailDef = (elementName: string) => {
  return emailDef(elementName, {
    fieldRequired: true,
    allowSpecialChars: true,
    customTranslation: (translations) =>
      translations.providersearch.loginModal.createAccount.emailErrorNotEmail,
  });
};

export const ProviderRegistrationInfo = ({
  aligned,
}: {
  aligned?: boolean;
}) => {
  const translations = useTranslations();
  const externalLinkLabel = useExternalLinkLabel();

  return (
    <VerticalLayout
      gap={sizing(1)}
      overflow="visible"
      aligned={aligned}
      margin={margin(1, 0)}
    >
      <Body
        as="p"
        fontSize={FONT_SIZE_14}
        margin={margin(0)}
        fontWeight={FONT_WEIGHT_SEMI_BOLD}
      >
        {translations.providersearch.loginDropDown.providersTitle}
      </Body>
      <Body as="p" fontSize={FONT_SIZE_14} margin={margin(0)}>
        <TranslationComposition
          translations={
            translations.providersearch.loginDropDown.providersInformation
          }
          withOptions
        >
          {([before, link, after]) => (
            <>
              {before}
              <LinkV2
                aria-label={externalLinkLabel(link, "email")}
                sx={{ fontSize: FONT_SIZE_14, display: "block" }}
                href={`mailto:${link}`}
              >
                {link}
              </LinkV2>
              {after}
            </>
          )}
        </TranslationComposition>
      </Body>
    </VerticalLayout>
  );
};
