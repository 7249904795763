import { useMemo, useState } from "react";

export default function useHover() {
  const [isHover, setIsHover] = useState(false);
  const onHoverProps = useMemo(
    () => ({
      onMouseEnter: () => setIsHover(true),
      onMouseLeave: () => setIsHover(false),
    }),
    [],
  );

  return { isHover, onHoverProps };
}
